<template>
  <div class="container">
    <a :href="route">
      <p class="control-btn">{{ btnName }}</p>
   </a>
  </div>
</template>

<script>
export default {
  Name: 'ControlBtn',
  props: {
    btnName: {
      type: String,
      required: true
    },
    route: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_base.scss";
@import "../assets/scss/reset.scss";
.control-btn {
    background-color: #A17560;
    border-radius: 4px;
    width: 187px;
    height: 40px;
    font-family: 'Noto Serif TC';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
    .control-btn{
      margin-bottom: 67px;
    }
}
@media screen and (min-width: 1440px) {
    .control-btn {
      margin-bottom: 64px;
    }
}
</style>
