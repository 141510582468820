<template>
  <footer>
        <p>Copyright © 2022 Nick Lin.</p>
        <!-- <div class="horizon"></div>
        <div class="d-flex flex-row align-items-center justify-content-center">
            <p class="copyright">Copyright © 202202120</p>
            <button class="contact-us" @click="contactUs()">聯 絡 我</button>
        </div> -->
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    contactUs () {
      location.href = '/contact'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_base.scss";
@import "../assets/scss/reset.scss";
footer {
    // width: 100vw;
    min-height: 22px;
    margin: 0;
    padding: 0 0 8px;
    p {
        text-align: center;
    }
}
</style>
