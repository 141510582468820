import { apiHelper } from '../utils/helpers'
export default {
  getBlogList (page) {
    return apiHelper.get(`/web/blog/blog?page=${page}`)
  },
  getBlogsByPage (page) {
    return apiHelper.get('/web/blog/blog?page=1_method=GET')
  },
  getBlogTypeList () {
    return apiHelper.get('/web/blog/blog_type/select?_method=GET')
  },
  getBlog (blogId) {
    return apiHelper.get('/web/blog/blog/' + blogId + '?_method=GET')
  },
  submitForm ({ formData }) {
    return apiHelper.post('/web/contact/contact', formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
  }
}
