<template>
    <div class="container">
        <div class="back-to-top" @click.stop.prevent="scrollToTop"><i class="fas fa-arrow-up"></i></div>
    </div>
</template>

<script>
export default {
  name: 'backToTopBtn',
  methods: {
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_base.scss";
@import "../assets/scss/reset.scss";
.back-to-top {
    width: 37.5px;
    height: 40px;
    background-color: #A17560;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
        font-size: 15px;
        line-height: 25px;
        color: #fff;
        text-align: center;
    }
}
</style>
