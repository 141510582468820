<template>
  <header>
    <div class="container d-flex justify-content-center">
      <div class="location d-flex align-items-center">
        <i class="fas fa-map-marker-alt"></i>
        <p>Taiwan,Taipei</p>
      </div>
      <div class="email d-flex align-items-center">
        <i class="fas fa-envelope"></i>
        <p>s0913741399@gmail.com</p>
      </div>
        <!-- <nav class="nav">
          <label class="navbar-toggle-label hamburger d-flex align-items-center justify-content-center" for="navbar-toggle">
          </label>
          <input type="checkbox" id="navbar-toggle" />
          <ul class="nav-list d-flex flex-column align-items-start">
            <a href="/about"><li class="nav-item"><h4>關於我</h4></li></a>
            <a href="/blog"><li class="nav-item"><h4>一些小文章</h4></li></a>
            <a href="/blog?keyword=Portfolio"><li class="nav-item"><h4>作品集</h4></li></a>
          </ul>
          <h3 class="brand"><a href="/">HI I’m Monica</a></h3>
        </nav> -->
      </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_base.scss";
@import "../assets/scss/reset.scss";
header {
  width: 100%;
  height: 42px;
  background-color: #F4F4F4;
  border-bottom: 0.5px solid #dee2e6;
  .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  p {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  .location {
    height: 42px;
    margin-right: 31px;
    i {
      margin-right: 10px;
      color: #A17560;
    }
  }
  .email {
    height: 42px;
    i {
      margin-right: 10px;
      color: #A17560;
    }
  }
}
@media screen and (min-width: 768px) {
  header {
    width: 100%;
    height: 42px;
    padding-right: 32px;
    .container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
    p {
      font-family: 'Amiri';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
    .location {
      height: 42px;
      margin-right: 31px;
      i {
        margin-right: 10px;
        color: #A17560;
      }
    }
    .email {
      height: 42px;
      i {
        margin-right: 10px;
        color: #A17560;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  header {
    z-index: 2;
    // position: absolute;
    // z-index: 0;
  }
}
</style>
