<template>
    <div>
        <label class="bg"  for="navbar-toggle">
            <label class="navbar-toggle-label hamburger d-flex align-items-center justify-content-center" for="navbar-toggle">
            </label>
        </label>
        <nav class="nav">
            <!-- <label class="navbar-toggle-label hamburger d-flex align-items-center justify-content-center" for="navbar-toggle">
            </label> -->
            <input type="checkbox" id="navbar-toggle" />
            <ul class="nav-list d-flex flex-column align-items-start">
                <label for="navbar-toggle"><li class="nav-item"><i class="fas fa-times"></i></li></label>
                <a href="/about">
                  <li class="nav-item">
                    <i :class="['fab fa-accusoft', {'high-light': keyword === '/about'}]"></i>
                    <h4 :class="['item-name', {'high-light': keyword === '/about'}]">
                      ABOUT ME
                    </h4>
                  </li>
                </a>
                <a href="/">
                  <li class="nav-item">
                    <i :class="['fab fa-canadian-maple-leaf', {'high-light': keyword === '/'}]" ></i>
                    <h4 :class="['item-name', {'high-light': keyword == '/'}]">
                      HOME
                    </h4>
                  </li>
                </a>
                <a href="/blog">
                  <li class="nav-item">
                    <i :class="['fas fa-camera-retro', {'high-light': keyword == '/blog'}]"></i>
                    <h4 :class="['item-name', {'high-light': keyword == '/blog'}]">
                      BLOG
                    </h4>
                  </li>
                </a>
                <a href="/contact">
                  <li class="nav-item">
                    <i :class="['fas fa-envelope-open-text', {'high-light': keyword == '/contact'}]"></i>
                    <h4 :class="['item-name', {'high-light': keyword == '/contact'}]">
                      CONTACT
                    </h4>
                  </li>
                </a>
                <a href="https://medium.com/@s0913741399">
                  <li class="nav-item">
                    <i class="fab fa-medium-m"></i>
                    <h4 class="item-name">
                      MEDIUM
                    </h4>
                  </li>
                </a>
                <a href="https://www.instagram.com/n1ck_1026/">
                  <li class="nav-item">
                    <i class="fab fa-instagram-square"></i>
                    <h4 class="item-name">
                      INSTAGRAM
                    </h4>
                  </li>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100000204357353">
                  <li class="nav-item">
                    <i class="fab fa-facebook"></i>
                    <h4 class="item-name">
                      FACEBOOK
                    </h4>
                  </li>
                </a>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      keyword: String
    }
  },
  created () {
    this.fetchKeyword()
  },
  methods: {
    fetchKeyword () {
      const url = new URL(location.href)
      this.keyword = url.pathname
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_base.scss";
@import "../assets/scss/reset.scss";
%hamburger {
  width: 25px;
  height: 0px;
  background-color: #000;
  border: 2px solid #000;
}
.nav {
  position: absolute;
  left: 0;
  top: 0;
}
.bg {
  position: absolute;
  background-color: #3D3D3B;
  width: 70px;
  height: 70px;
  right: 0;
  top: 0;
}
.nav-list {
  transform: scale(1, 0);
  width: 100%;
  padding-top: 0;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 2;
  .nav-item {
    margin: 0;
    width: 240px;
    height: 50px;
    opacity: 0;
    cursor: pointer;
    background-color: #EAE6E6;
    align-items: center;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-flow: row nowrap;
    .fas, .fab {
      width: 30px;
      height: 30px;
      font-size: 24px;
      line-height: 30px;
      margin-left: 21px;
      margin-right: 8px;
      text-align: center;
      color: #959595;
    }
    .item-name {
      width: 69px;
      text-align: center;
      @include font-setting('Lora', normal, 400, 12px, 50px, #000);
    }
    .high-light {
      color: #6F4E3B
    }
  }
}
//本體
.navbar-toggle-label {
  grid-column: 1/2;
  cursor: pointer;
  margin-left: 13px;
  align-items: center;
}
//check box
#navbar-toggle {
  position: absolute;
  top: 0;
  display: none;
  /* navbar hamburger */
  &:checked ~ .nav-list {
    transform: scale(1, 1);
    transform-origin: top;
    transition: transform 0.3s ease-out;
  }
  &:checked ~ * .nav-item {
    transition: opacity 0.2s ease-out 0.1s;
    opacity: 1;
  }
}
.hamburger {
    position: absolute;
    top: 35px;
    left: 10px;
    @extend %hamburger;
    // width: 25px;
    &::before {
        @extend %hamburger;
        position: absolute;
        content: '';
        top: 6px;
        width: 21px;
    }
    &::after {
        @extend %hamburger;
        position: absolute;
        content: '';
        bottom: 6px;
        width: 21px;
    }
}
@media screen and (min-width: 1440px) {
  .bg {
      display: none;
  }
  .nav-list {
    transform: scale(1, 1);
    top: 0;
    height: 100vh;
    background-color: #EAE6E6;
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    .nav-item {
      width: 90px;
      height: 90px;
      opacity: 1;
      margin: 0;
      padding: 0;
      flex-flow: column nowrap;
      justify-content: center;
      box-shadow: none;
      border-bottom: solid 1px #F4F4F4;
      .fab, .fas {
          margin: 0 0 10px;
      }
      .item-name {
        line-height: 15px;
      }
    }
    label {
        display: none;
    }
  }
}
</style>
